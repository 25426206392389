/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-nested-ternary */
const prod = process.env.NODE_ENV === "production";
const devLive = process.env.React_App_DEV_LIVE || "live";
const devStaging = process.env.React_App_DEV_LIVE === "staging" || false;
console.log("devStaging", devLive);

// const serverUrl = devLive
//   ? "https://api.letsgoplan.com/"
//   : devStaging
//   ? "https://staging-api.letsgoplan.com/"
//   : "http://192.168.1.81:9000/";

//"http://192.168.1.57:5000/";

const baseUrl =
  devLive === "live"
    ? "https://api.letsgoplan.com/"
    : devLive === "staging"
      ? "https://staging-api.letsgoplan.com/"
      : "http://192.168.1.81:9000/";

const socketUrl =
  devLive === "live"
    ? "https://chat.letsgoplan.com/"
    : devLive === "staging"
      ? "https://staging-chat.letsgoplan.com/"
      : "http://192.168.1.81:3001";

const webSite =
  devLive === "live"
    ? "https://admin.letsgoplan.com/"
    : devLive === "staging"
      ? "https://staging-admin.letsgoplan.com/"
      : "http://localhost:3000";

console.log("webSite", webSite);

export default {
  siteName: "React",
  siteIcon: "ion-flash",
  footerText: `© ${new Date().getFullYear()} test`,
  apiUrl: baseUrl,
  socketUrl: socketUrl,
  csvUrl: prod ? `SampleCsv` : "http://192.168.1.62:9000/SampleCsv",
  domainUrl: webSite,
  sailsUrl: "http://localhost:33969/",

  googleAutoCompleteKey: "AIzaSyA-bDjvT_FZDlmzBOhXotMaSqQ6VcHrGEk",
  GIPHYApiKey: "lnMVUf8H6KqoRUlBJRhAdZIQBHvSi1oc",
  urlRegex: /(https?:\/\/[^\s'"\\]+|www\.[^\s'"\\]+)/g,

  endpoints: {
    login: "user/login",
    logout: "admin/user/logout",
    adminConfigurationList: "admin/admin-configuration/list",
    adminConfigurationDelete: "admin/admin-configuration/delete",
    adminConfigurationUpdate: "admin/admin-configuration/update",
    adminConfigurationCreate: "admin/admin-configuration/create",
    itineraryList: "admin/itinerary/list",
    apiLogList: "admin/api-log/list",
    apiLogListDelete: "admin/api-log/delete",
    errorLogList: "admin/error-log/list",
    errorLogListDelete: "admin/error-log/delete",
    cmsList: "admin/cms/list",
    cmsListDelete: "admin/cms/delete",
    cmsListUpdate: "admin/cms/update",
    cmsListCreate: "admin/cms/create",
    emailTemplateList: "admin/email-template/list/",
    emailTemplateListDelete: "admin/email-template/delete",
    emailTemplateListStatusUpdate: "admin/email-template/status-change",
    emailTemplateListUpdate: "admin/email-template/update",
    emailTemplateListCreate: "admin/email-template/create",
    faqCategoryCreate: "admin/faq-category/create",
    faqCategoryUpdate: "admin/faq-category/update",
    faqCategoryList: "admin/faq-category/list",
    faqCategoryDelate: "admin/faq-category/delete",
    faqCategoryStatusChange: "admin/faq-category/status-change",
    faqList: "admin/faq/list",
    faqListDelete: "admin/faq/delete",
    faqListUpdate: "admin/faq/update",
    faqListCreate: "admin/faq/create",
    travelPreferencesList: "admin/travel-preferences/list",
    travelPreferencesListDelete: "admin/travel-preferences/delete",
    travelPreferencesListStatusChange: "admin/travel-preferences/status-change",
    travelPreferencesListUpdate: "admin/travel-preferences/update",
    travelPreferencesListCreate: "admin/travel-preferences/create",
    introScreenListUpdatePosition: "admin/intro-screen/update-position",
    introScreenList: "admin/intro-screen/list",
    introScreenVideoRemove: "v1/common/remove-image",
    introScreenListUpdate: "admin/intro-screen/update",
    tripCategoryList: "admin/trip-category/list",
    tripCategoryListDelete: "admin/trip-category/delete",
    tripCategoryListStatusChange: "admin/trip-category/status-change",
    tripCategoryListUpdate: "admin/trip-category/update",
    tripCategoryListCreate: "admin/trip-category/create",
    soloTrips: "admin/trips/get-solo-trips",
    EventManagement: "admin/event/get-public-private-event-list",
    EventManagementDetail: "admin/event/get-event-details",
    EventManagementDeletedList: "admin/event/get-deleted-events",
    convertEventPubPrivate: "admin/event/convert-public-to-private",
    GroupTrip: "admin/trips/get-group-trips",
    SponsoredTrip: "admin/trips/get-pending-sponsored-trips",
    TripDetails: "v1/trip/get-trip",
    convertPublicPrivate: "admin/trips/convert-public-to-private",
    SponsoredTripRequest: "admin/trips/accept-or-reject-sponsore-trip",
    usersList: "admin/user/get-user-list",
    usersVendorList: "admin/vendor/all-vendor-list",
    usersDetail: "admin/user/get-user-detail",
    notificationsList: "admin/notification/list",

    serviceRequestList: "admin/service/all-service-request-list",
    serviceRequestDetailLogs: "admin/service/service-request-quotes",

    serviceList: "admin/service/list",
    serviceCreate: "admin/service/create",
    serviceUpdate: "admin/service/update",
    serviceDelate: "admin/service/delete",
    serviceStatusChange: "admin/service/update-service-status",
    cuisineList: "admin/cuisine/list",
    cuisineCreate: "admin/cuisine/create",
    cuisineUpdate: "admin/cuisine/update",
    cuisineDelate: "admin/cuisine/delete",
    includeList: "admin/include/list",
    includeCreate: "admin/include/create",
    includeUpdate: "admin/include/update",
    includeDelate: "admin/include/delete",
    includeStatus: "admin/include/update-include-status",
    ourPickSave: "admin/our-picks/get-our-picks-data",
    ourPickList: "admin/our-picks/our-picks-list",
    ourPickCreate: "admin/our-picks/save-our-picks-data",
    ourPickLogList: "admin/our-picks/our-picks-log-list",
    ourPickDelete: "admin/our-picks/delete-our-pick",
    vendorList: "admin/vendor/list",
    vendorApproveRejectList: "admin/vendor/approve-reject-vendor",
    franchisesList: "admin/franchises/franchise-list",
    franchisesCreate: "admin/franchises/create-franchise",
    franchisesUpdate: "admin/franchises/update-franchise",
    franchisesDelate: "admin/franchises/delete-franchise",
    raiseIssueReasonList: "admin/issue-reasons/issue-reason-list",
    raiseIssueReasonCreate: "admin/issue-reasons/create-issue-reason",
    raiseIssueReasonUpdate: "admin/issue-reasons/update-issue-reason",
    raiseIssueReasonDelate: "admin/issue-reasons/delete-issue-reason",
    raiseIssueReasonStatus: "admin/issue-reasons/issue-reason-status-change",
    chatReportList: "admin/admin-chat/get-report-chat-list",
    chatConvertationList: "admin/admin-chat/get-conversations",
    chatUserBlock: "admin/user/block-unblock-user",
    raiseIssueList: "admin/issue-reasons/issues-list",
    paymentRefundPayout: "v1/payment/refund-payout-amount",
    raiseIssueStatus: "admin/issue-reasons/issue-status-change",
    purchaseTokenList: "admin/purchase-token/purchase-token-list",
    purchaseTokenCreate: "admin/purchase-token/create-purchase-token",
    purchaseTokenUpdate: "admin/purchase-token/update-purchase-token",
    purchaseTokenDelate: "admin/purchase-token/delete-purchase-token",
    purchaseTokenStatusChange:
      "admin/purchase-token/purchase-token-status-change",
    itinerarySettingsList: "admin/itinerary-settings/itinerary-settings-list",
    itinerarySettingsUpdate:
      "admin/itinerary-settings/update-itinerary-setting",
    userUsedTokens: "admin/user/get-user-used-tokens",
    userTokensCreditDebit: "admin/token/add-subtract-tokens",
    activeDelativeUser: "admin/user/activate-deactivate-user",
    activeDativeVendor: "admin/vendor/activate-deactivate-vendor",
    csvExportApi: "admin/admin/export-data",
    activelanguageList: "v1/language/get-active-languages",

    userSubscriptionPlan: "admin/subscription-plan/get-user-plan",
    subscriptionPlanCreate: "admin/subscription-plan/create",
    subscriptionPlanUpdate: "admin/subscription-plan/update",
    subscriptionPlanList: "admin/subscription-plan/list",
    subscriptionPlanDelate: "admin/subscription-plan/delete",
    subscriptionPlanStatusChange:
      "admin/subscription-plan/update-active-status",

    ratingList: "admin/lets-go-ratings/get-ratings-list",
    ratingDelete: "admin/lets-go-ratings/delete-rating",
    ratingStatusChange: "admin/lets-go-ratings/change-rating-status",

    addSurpriseCities: "admin/surprise-me-cities/add",
    surpriseCities: "admin/surprise-me-cities/list",
    surpriseCitiesDelete: "admin/surprise-me-cities/delete",
    surpriseMeItinerary: "admin/surprise-me/list",

    dashboardCharts: "admin/analytics/admin-analytics",
    dashboardChartTwo: "admin/analytics/admin-analytics-data",

    popularDestinationList:
      "admin/popular-destination/popular-destination-list",
    popularDestinationCreateUpdate:
      "admin/popular-destination/add-update-popular-destination",
    popularDestinationDelete:
      "admin/popular-destination/delete-popular-destination",

    commonData: "v1/common/get-common-data",

    ChatBotSuggestionCreate: "admin/ai-chatbot-suggestion/create",
    ChatBotSuggestionDelete: "admin/ai-chatbot-suggestion/delete",
    ChatBotSuggestionList: "admin/ai-chatbot-suggestion/list",
    ChatBotSuggestionUpdate: "admin/ai-chatbot-suggestion/update",
    ChatBotSuggestionStatusChange: "admin/ai-chatbot-suggestion/status-change",

    PopularDestinationLogs:
      "admin/popular-destination/popular-destination-log-list",

    deletedUserList: "admin/user/get-deleted-user-list",
    deletedVendorList: "admin/vendor/deleted-vendor-list",

    stripeConnectBalance: "admin/analytics/fetch-stripe-connected-balance",

    // withdrawal-config
    minMaxwithdrawalList: "admin/min_max_withdrawal/list",
    minMaxwithdrawalListDelete: "admin/min_max_withdrawal/delete",
    currencyList: "admin/currency/list",
    minMaxwithdrawalListAdd: "admin/min_max_withdrawal/create",
    minMaxwithdrawalListUpdate: "admin/min_max_withdrawal/update",
    searchMembers: "admin/user/get-all-active-users",

    // referral details
    referralList: "admin/user/get-referral-users-list",
  },
  google: {
    analyticsKey: "UA-xxxxxxxxx-1",
  },
  tinyEditorKey: "tdph45ujaqoy7h2ctckgtzryt9pm1srlgvh8jskrcc46wtf6", //  Added by pavan patil with email :- pavan.patil@groovyweb.co
  dashboard: "/dashboard",
};
